import { useHistory } from 'react-router-dom'

import SpendingLimitBarUser from '../requests/SpendingLimitBarUser'

import { Button } from 'mmfintech-portal-commons'
import { AccountsOverview } from '../account'
import { DepositIcon, ExchangeIcon, PayoutIcon, WithdrawIcon } from '../../icons'
import { AccountButtons, StaffDashboardHeader, StaffDashboardWrapper } from './Dashboard.styled'

import { isValidArray, tr } from 'mmfintech-commons'
import { actions, paths, useMerchantAccounts, usePermissions } from 'mmfintech-backend-api'

import { PortalUserPermissionsEnum } from 'mmfintech-commons-types'

export const DashboardStaff = () => {
  const { isOwnerOrAdministrator, hasAccessTo, hasWritePermission } = usePermissions()

  const { accounts: paymentAccounts } = useMerchantAccounts()

  const history = useHistory()

  const handleSendMoneyClick = () => {
    actions.routing.setWithdrawOrigin('')
    history.push(paths.payout.select())
  }

  return (
    <StaffDashboardWrapper>
      {hasWritePermission() ? (
        <StaffDashboardHeader>
          <SpendingLimitBarUser />

          <AccountButtons>
            {hasWritePermission() && (
              <Button
                color='alternative'
                icon={<DepositIcon />}
                text={tr('FRONTEND.DASHBOARD.BUTTON_DEPOSIT', 'Deposit')}
                onClick={() => history.push(paths.banking.deposit())}
                data-test='button-deposit'
              />
            )}
            {hasAccessTo(PortalUserPermissionsEnum.WITHDRAWALS) && isValidArray(paymentAccounts) && (
              <Button
                color='alternative'
                icon={<WithdrawIcon />}
                text={tr('FRONTEND.DASHBOARD.BUTTON_WITHDRAW', 'Withdraw')}
                onClick={() => history.push(paths.withdraw.select())}
                data-test='button-withdraw'
              />
            )}
            {isOwnerOrAdministrator() && hasWritePermission() && (
              <Button
                color='alternative'
                icon={<ExchangeIcon />}
                text={tr('FRONTEND.DASHBOARD.BUTTON_EXCHANGE', 'Exchange')}
                onClick={() => history.push(paths.banking.exchange())}
                data-test='button-exchange-link'
              />
            )}
            {hasAccessTo(PortalUserPermissionsEnum.PAYOUTS) && isValidArray(paymentAccounts) ? (
              <Button
                color='alternative'
                icon={<PayoutIcon />}
                text={tr('FRONTEND.DASHBOARD.BUTTON_PAYOUT', 'Payout')}
                onClick={handleSendMoneyClick}
                data-test='button-withdraw'
              />
            ) : null}
          </AccountButtons>
        </StaffDashboardHeader>
      ) : null}

      <AccountsOverview />
    </StaffDashboardWrapper>
  )
}
