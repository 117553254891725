import React from 'react'

import { CurrencyIcon } from '../CurrencyIcon'
import { CurrencyBadgeWrapper } from './CurrencyBadge.styled'

import CheckMark from '../../images/icons/password-terms-check.svg?react'

interface CurrencyBadgeProps {
  currencyCode: string
  isSelected: boolean
  onClick: (e: React.SyntheticEvent) => void
}

export const CurrencyBadge = ({ currencyCode, isSelected, onClick }: CurrencyBadgeProps) => {
  return (
    <CurrencyBadgeWrapper isSelected={isSelected} onClick={onClick}>
      <div>
        <CurrencyIcon currency={currencyCode} circle width='2.2rem' height='2.2rem' />
      </div>
      <span data-test='currency' className='currency-name'>
        {currencyCode}
      </span>
      {isSelected && <CheckMark />}
    </CurrencyBadgeWrapper>
  )
}
