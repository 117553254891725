import { useEffect, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import toast from 'react-hot-toast'
import ReactTooltip from 'react-tooltip'

import { SelectInput } from '../../components'
import { AccountDataRow } from './AccountTableRow'
import { IbanRequestModal } from './IbanRequestModal'
import { AccountCreateModal } from './AccountCreateModal'
import { AccountUpdateModal } from './AccountUpdateModal'
import { Button, DataTable, ErrorDisplay, Pagination, Preloader } from 'mmfintech-portal-commons'
import { AccountHeader, AccountsContainer, AccountsWrapper, CurrenciesSelectStyled } from './Accounts.styled'
import { DepositIcon, ExchangeIcon, NewAccountIcon, PaymentLinkIcon, PayoutIcon, WithdrawIcon } from '../../icons'

import { useOnboarding } from '../../hooks'
import {
  extractCurrencyCode,
  GlobalContext,
  isValidArray,
  isValidObject,
  tr,
  usePaginationQuery
} from 'mmfintech-commons'
import {
  paths,
  useAppSelector,
  selectMerchantCapabilities,
  useLazyGetPaymentAccountsFilteredQuery,
  useGetSupportedSchemesAndCurrenciesQuery,
  selectCurrentLanguage,
  usePermissions,
  useMerchantAccounts
} from 'mmfintech-backend-api'

import { AccountBalanceOut, PortalUserPermissionsEnum } from 'mmfintech-commons-types'

import RequestIcon from '../../images/icons/request-iban-icon.svg?react'

const Accounts = () => {
  const { modalShow } = useContext(GlobalContext)
  const selectedLanguage = useAppSelector(selectCurrentLanguage)

  const capabilities = useAppSelector(selectMerchantCapabilities)

  const { accounts: paymentAccounts } = useMerchantAccounts()

  const [fetchAccounts, { paymentAccountsList, paymentAccountsListFetching, paymentAccountsListError }] =
    useLazyGetPaymentAccountsFilteredQuery({
      selectFromResult: ({ data, isFetching, error }) => ({
        paymentAccountsList: isValidArray(data?.content) ? data.content : [],
        paymentAccountsListFetching: isFetching,
        paymentAccountsListError: error
      })
    })

  const { ibanCurrencies } = useGetSupportedSchemesAndCurrenciesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      ibanCurrencies: data?.currencies ? data.currencies : {}
    })
  })

  const { enablePortalWalletCheckout } = capabilities || {}

  const [selectedCurrency, setSelectedCurrency] = useState('all')
  const [filteredCurrencies, setFilteredCurrencies] = useState([])

  const history = useHistory()

  const { shouldRequestIban } = useOnboarding()
  const { hasAccessTo, hasWritePermission, isOwnerOrAdministrator } = usePermissions()

  const pagination = usePaginationQuery({
    rowsPerPage: 10,
    reload: async ({ additionalParams, ...rest }, onSuccess) => {
      const { currencyCode } = additionalParams || {}
      const filter = {
        ...rest,
        ...(currencyCode !== 'all' ? { currencyCode } : null)
      }
      try {
        const response = await fetchAccounts(filter).unwrap()
        if (response) {
          onSuccess(response)
        }
      } catch (_err) {}
    }
  })

  const handleNewAccountClick = () => {
    modalShow({
      options: { closeOnClickOutside: false, size: 'auto', overflow: 'none' },
      content: (
        <AccountCreateModal
          onSuccess={(currencyCode: string) => {
            setSelectedCurrency('all')
            toast.remove()
            toast.success(
              tr('FRONTEND.ACCOUNTS.SUCCESS_MESSAGE', '{{CURRENCY}} account successfully added', {
                CURRENCY: currencyCode
              })
            )
          }}
        />
      )
    })
  }

  const handleEditAccountClick = (account: AccountBalanceOut): void => {
    modalShow({
      options: {
        closeOnClickOutside: false,
        size: 'auto',
        overflow: 'none'
        // onClose: () => dispatch(actions.banking.editAccountCleanup())
      },
      content: (
        <AccountUpdateModal
          account={account}
          onSuccess={() => {
            setSelectedCurrency('all')
          }}
        />
      )
    })
  }

  const handleIbanRequestClick = () => {
    modalShow({
      options: {
        closeOnClickOutside: false,
        size: 'auto',
        onClose: () => {
          // dispatch(actions.iban.ibanPreviewCleanup())
        }
      },
      content: <IbanRequestModal reload={() => pagination.reload()} />
    })
  }

  useEffect(() => {
    ReactTooltip.rebuild()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const all = [{ value: 'all', label: tr('FRONTEND.ACCOUNTS.ALL_CURRENCIES', 'All currencies') }]
    if (isValidArray(paymentAccounts)) {
      const currencies = paymentAccounts
        .map(account => extractCurrencyCode(account))
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort()
      setFilteredCurrencies([...all, ...currencies.map(c => ({ value: c, label: c }))])
    } else {
      setFilteredCurrencies(all)
    }
  }, [paymentAccounts, selectedLanguage])

  useEffect(() => {
    pagination.reload({ additionalParams: { currencyCode: selectedCurrency } })
    // eslint-disable-next-line
  }, [selectedCurrency])

  return (
    <AccountsWrapper>
      <AccountHeader>
        <div className='left-side'>
          {isOwnerOrAdministrator() ? (
            <Button
              icon={<NewAccountIcon />}
              color='alternative'
              text={tr('FRONTEND.ACCOUNTS.NEW_ACCOUNT_BUTTON', 'New account')}
              onClick={handleNewAccountClick}
              data-test='button-new-account'
            />
          ) : null}
          {isOwnerOrAdministrator() && isValidObject(ibanCurrencies) && shouldRequestIban ? (
            <Button
              icon={<RequestIcon />}
              color='alternative'
              text={tr('FRONTEND.ACCOUNTS.REQUEST_IBAN_BUTTON', 'Request IBAN')}
              onClick={handleIbanRequestClick}
              data-test='button-iban-request'
            />
          ) : null}

          <SelectInput
            name='search-option'
            dataTest='select-currency-dropdown'
            value={selectedCurrency}
            onChange={(_name, value) => setSelectedCurrency(value)}
            options={filteredCurrencies}
            applyStyles={CurrenciesSelectStyled}
            disableSearch={true}
            hideLabel
            hideRequired
            hideErrorLine
          />
        </div>

        <div className='right-side'>
          {hasWritePermission() ? (
            <Button
              color='alternative'
              icon={<DepositIcon />}
              text={tr('FRONTEND.DASHBOARD.BUTTON_DEPOSIT', 'Deposit')}
              onClick={() => history.push(paths.banking.deposit())}
              data-test='button-deposit'
            />
          ) : null}
          {hasAccessTo(PortalUserPermissionsEnum.WITHDRAWALS) && hasWritePermission() ? (
            <Button
              color='alternative'
              icon={<WithdrawIcon />}
              text={tr('FRONTEND.DASHBOARD.BUTTON_WITHDRAW', 'Withdraw')}
              onClick={() => history.push(paths.withdraw.select())}
              data-test='button-withdraw'
            />
          ) : null}
          {isOwnerOrAdministrator() && hasWritePermission() ? (
            <Button
              color='alternative'
              icon={<ExchangeIcon />}
              text={tr('FRONTEND.DASHBOARD.BUTTON_EXCHANGE', 'Exchange')}
              onClick={() => history.push(paths.banking.exchange())}
              data-test='button-exchange'
            />
          ) : null}
          {enablePortalWalletCheckout ? (
            <Button
              color='alternative'
              icon={<PaymentLinkIcon />}
              text={tr('FRONTEND.DASHBOARD.BUTTON_PAYMENT_LINK', 'Payment link')}
              onClick={() => history.push(paths.scanAndPay())}
              data-test='button-payment-link'
            />
          ) : null}
          {hasAccessTo(PortalUserPermissionsEnum.PAYOUTS) && hasWritePermission() ? (
            <Button
              color='alternative'
              icon={<PayoutIcon />}
              text={tr('FRONTEND.DASHBOARD.BUTTON_PAYOUT', 'Payout')}
              onClick={() => history.push(paths.payout.select())}
              data-test='button-payout'
            />
          ) : null}
        </div>
      </AccountHeader>

      {paymentAccountsListFetching ? (
        <Preloader />
      ) : paymentAccountsListError ? (
        <ErrorDisplay error={[paymentAccountsListError]} />
      ) : (
        <>
          <AccountsContainer>
            <DataTable.Table scale2='768px'>
              <DataTable.Head>
                <DataTable.Row>
                  <DataTable.HeadCell>{tr('FRONTEND.ACCOUNTS.COLUMNS.CURRENCY', 'Currency')}</DataTable.HeadCell>
                  <DataTable.HeadCell>{tr('FRONTEND.ACCOUNTS.COLUMNS.NAME', 'Account name')}</DataTable.HeadCell>
                  <DataTable.HeadCell>
                    {tr('FRONTEND.ACCOUNTS.COLUMNS.ACCOUNT_DETAILS', 'Account details')}
                  </DataTable.HeadCell>
                  <DataTable.HeadCell>{tr('FRONTEND.ACCOUNTS.COLUMNS.ACCOUNT_ID', 'Account ID')}</DataTable.HeadCell>
                  <DataTable.HeadCell>{tr('FRONTEND.ACCOUNTS.COLUMNS.BALANCE', 'Balance')}</DataTable.HeadCell>
                  {isOwnerOrAdministrator() && <DataTable.HeadCell />}
                </DataTable.Row>
              </DataTable.Head>
              <DataTable.Body>
                {paymentAccountsList?.map((account, index) => (
                  <AccountDataRow account={account} key={index} handleEdit={handleEditAccountClick} />
                ))}
              </DataTable.Body>
            </DataTable.Table>
          </AccountsContainer>

          <Pagination className='accounts-pagination' {...pagination.register()} />
          <ReactTooltip id='info-tooltip' type='light' effect='solid' place='top' multiline={true} isCapture={true} />
        </>
      )}
    </AccountsWrapper>
  )
}

export default Accounts
