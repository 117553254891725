import styled from 'styled-components'

import AED from '../../images/currencies/aed.png'
import ARS from '../../images/currencies/ars.png'
import AUD from '../../images/currencies/aud.svg'
import BGN from '../../images/currencies/bgn.svg'
import BHD from '../../images/currencies/bhd.png'
import BRL from '../../images/currencies/brl.svg'
import BTC from '../../images/currencies/btc.svg'
import CAD from '../../images/currencies/cad.png'
import CHF from '../../images/currencies/chf.svg'
import CLP from '../../images/currencies/clp.svg'
import COP from '../../images/currencies/cop.svg'
import CZK from '../../images/currencies/czk.svg'
import DKK from '../../images/currencies/dkk.svg'
import EGP from '../../images/currencies/egp.png'
import ETH from '../../images/currencies/eth.svg'
import EUR from '../../images/currencies/eur.png'
import GBP from '../../images/currencies/gbp.svg'
import GHS from '../../images/currencies/ghs.png'
import HUF from '../../images/currencies/huf.svg'
import JPY from '../../images/currencies/jpy.svg'
import KES from '../../images/currencies/kes.png'
import KRW from '../../images/currencies/krw.svg'
import MAD from '../../images/currencies/mad.png'
import MXN from '../../images/currencies/mxn.svg'
import NGN from '../../images/currencies/ngn.png'
import NOK from '../../images/currencies/nok.png'
import NZD from '../../images/currencies/nzd.png'
import OMR from '../../images/currencies/omr.png'
import PEN from '../../images/currencies/pen.png'
import PLN from '../../images/currencies/pln.svg'
import RON from '../../images/currencies/ron.svg'
import RWF from '../../images/currencies/rwf.png'
import SDG from '../../images/currencies/sdg.png'
import SEK from '../../images/currencies/sek.svg'
import SGD from '../../images/currencies/sgd.png'
import TRY from '../../images/currencies/try.svg'
import TZS from '../../images/currencies/tzs.png'
import UGH from '../../images/currencies/ugx.png'
import USD from '../../images/currencies/usd.svg'
import USDT from '../../images/currencies/usdt.svg'
import XAF from '../../images/currencies/xaf.png'
import ZMW from '../../images/currencies/zmw.png'

export const CurrencyIconWrapper = styled.span<{ width?: string; height?: string }>`
  display: inline-block;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;

  &.circle {
    border-radius: 50%;
  }

  &.AED {
    //prettier-ignore
    background-image: url("${AED}");
  }
  &.ARS {
    //prettier-ignore
    background-image: url("${ARS}");
  }
  &.AUD {
    //prettier-ignore
    background-image: url("${AUD}");
  }
  &.BGN {
    //prettier-ignore
    background-image: url("${BGN}");
  }
  &.BHD {
    //prettier-ignore
    background-image: url("${BHD}");
  }
  &.BRL {
    //prettier-ignore
    background-image: url("${BRL}");
  }
  &.BTC {
    //prettier-ignore
    background-image: url("${BTC}");
  }
  &.CHF {
    //prettier-ignore
    background-image: url("${CHF}");
  }
  &.CAD {
    //prettier-ignore
    background-image: url("${CAD}");
  }
  &.CLP {
    //prettier-ignore
    background-image: url("${CLP}");
  }
  &.CZK {
    //prettier-ignore
    background-image: url("${CZK}");
  }
  &.COP {
    //prettier-ignore
    background-image: url("${COP}");
  }
  &.DKK {
    //prettier-ignore
    background-image: url("${DKK}");
  }
  &.EGP {
    //prettier-ignore
    background-image: url("${EGP}");
  }
  &.ETH {
    //prettier-ignore
    background-image: url("${ETH}");
  }
  &.EUR {
    //prettier-ignore
    background-image: url("${EUR}");
  }
  &.GBP {
    //prettier-ignore
    background-image: url("${GBP}");
  }
  &.GHS {
    //prettier-ignore
    background-image: url("${GHS}");
  }
  &.HUF {
    //prettier-ignore
    background-image: url("${HUF}");
  }
  &.JPY {
    //prettier-ignore
    background-image: url("${JPY}");
  }
  &.KES {
    //prettier-ignore
    background-image: url("${KES}");
  }
  &.KRW {
    //prettier-ignore
    background-image: url("${KRW}");
  }
  &.MAD {
    //prettier-ignore
    background-image: url("${MAD}");
  }
  &.MXN {
    //prettier-ignore
    background-image: url("${MXN}");
  }
  &.NGN {
    //prettier-ignore
    background-image: url("${NGN}");
  }
  &.NOK {
    //prettier-ignore
    background-image: url("${NOK}");
  }
  &.NZD {
    //prettier-ignore
    background-image: url("${NZD}");
  }
  &.OMR {
    //prettier-ignore
    background-image: url("${OMR}");
  }
  &.PEN {
    //prettier-ignore
    background-image: url("${PEN}");
  }
  &.PLN {
    //prettier-ignore
    background-image: url("${PLN}");
  }
  &.RON {
    //prettier-ignore
    background-image: url("${RON}");
  }
  &.RWF {
    //prettier-ignore
    background-image: url("${RWF}");
  }
  &.SDG {
    //prettier-ignore
    background-image: url("${SDG}");
  }
  &.SEK {
    //prettier-ignore
    background-image: url("${SEK}");
  }
  &.SGD {
    //prettier-ignore
    background-image: url("${SGD}");
  }
  &.TRY {
    //prettier-ignore
    background-image: url("${TRY}");
  }
  &.TZS {
    //prettier-ignore
    background-image: url("${TZS}");
  }
  &.UGX {
    //prettier-ignore
    background-image: url("${UGH}");
  }
  &.USD {
    //prettier-ignore
    background-image: url("${USD}");
  }
  &.USDT,
  &.USDT-ERC20,
  &.USDT-TRC20 {
    //prettier-ignore
    background-image: url("${USDT}");
  }
  &.XOF,
  &.XAF {
    //prettier-ignore
    background-image: url("${XAF}");
  }
  &.ZMW {
    //prettier-ignore
    background-image: url("${ZMW}");
  }
`
