import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import cn from 'classnames'

import settings from '../../../settings'
import {
  fetchLastChunk,
  formatDateLocal,
  formatMoney,
  formatTime,
  GlobalContext,
  isValidString,
  tr
} from 'mmfintech-commons'

import { Button, DataTable } from 'mmfintech-portal-commons'
import { CustomizeTableModal } from './CustomizeTableModal'
import { TransactionDropdown } from './TransactionDropdown'
import { TransactionsContainer } from './TransactionsTable.styled'

import {
  actions,
  configuration,
  paths,
  useTransactionColumns,
  selectMerchantCapabilities,
  useAppSelector,
  formatTransactionDescription,
  extractCounterpartyName,
  usePermissions,
  getTransactionStatusName,
  useCurrencies
} from 'mmfintech-backend-api'

import {
  PaymentFlowEnum,
  PaymentMethodEnum,
  PortalUserPermissionsEnum,
  TransactionDirectionEnum,
  TransactionStatusEnum
} from 'mmfintech-commons-types'

import CustomizeIcon from '../../../images/icons/customize.svg?react'
import DetailAlternativeIcon from '../../../images/icons/detail-alternative-icon.svg?react'

export const TransactionsTable = ({ transactions, reload }) => {
  const { modalHide, modalShow } = useContext(GlobalContext)

  const { hasAccessTo } = usePermissions()
  const { getCurrencyPrecision } = useCurrencies()

  const capabilities = useAppSelector(selectMerchantCapabilities)
  const { enablePayouts, enableRefunds } = capabilities || {}

  const history = useHistory()
  const columns = useTransactionColumns({
    cookieDomain: configuration.isLocal() ? null : settings.cookieDomain,
    enableCustomerEmail: true,
    enableFailReason: true,
    defaultVisible: ['id', 'type', 'description', 'reference', 'status', 'foreignTransactionId']
  })

  const handleCustomizeClick = () => {
    modalShow({
      options: { closeOnClickOutside: false, maxWidth: 450, size: 'medium' },
      content: <CustomizeTableModal columns={columns} onClose={modalHide} />
    })
  }

  const handleDetailsClick = (transactionId: number) => {
    actions.routing.setTransactionDetailsOrigin('transactions')
    history.push(paths.banking.transactions.details(transactionId))
  }

  return (
    <TransactionsContainer className='transactions-table'>
      <div className='customize-columns-button'>
        <Button
          type='button'
          color='round-secondary'
          onClick={handleCustomizeClick}
          text={tr('FRONTEND.TRANSACTIONS.COLUMNS.CUSTOMIZE_BUTTON', 'Customize')}
          data-test='customize-columns'
          icon={<CustomizeIcon />}
        />
      </div>

      <DataTable.Table scale2='1200px'>
        <DataTable.Head>
          <DataTable.Row>
            {columns.isVisible('id') && (
              <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.ID', 'ID')}</DataTable.HeadCell>
            )}
            {columns.isVisible('foreignTransactionId') && (
              <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.FOREIGN_ID', 'Provided ID')}</DataTable.HeadCell>
            )}
            {columns.isVisible('account') && (
              <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.ACCOUNT', 'Account')}</DataTable.HeadCell>
            )}
            <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.DATE', 'Time')}</DataTable.HeadCell>
            {columns.isVisible('type') && (
              <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.TRANSACTION_TYPE', 'Type')}</DataTable.HeadCell>
            )}
            {columns.isVisible('method') && (
              <DataTable.HeadCell>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.PAYMENT_METHOD', 'Payment Method')}
              </DataTable.HeadCell>
            )}
            {columns.isVisible('description') && (
              <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.DESCRIPTION', 'Description')}</DataTable.HeadCell>
            )}
            {columns.isVisible('customer') && (
              <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.CUSTOMER', 'Counterparty')}</DataTable.HeadCell>
            )}
            {columns.isVisible('customerEmail') && (
              <DataTable.HeadCell>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.CUSTOMER_EMAIL', 'Customer email')}
              </DataTable.HeadCell>
            )}
            {columns.isVisible('customerName') && (
              <DataTable.HeadCell>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.CUSTOMER_NAME', 'Customer name')}
              </DataTable.HeadCell>
            )}
            {columns.isVisible('reference') && (
              <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.REFERENCE', 'Reference')}</DataTable.HeadCell>
            )}
            <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.AMOUNT', 'Amount')}</DataTable.HeadCell>
            {columns.isVisible('status') && (
              <DataTable.HeadCell className='thin'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS', 'Status')}
              </DataTable.HeadCell>
            )}
            {columns.isVisible('failReason') && (
              <DataTable.HeadCell className='thin'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS_DESCRIPTION', 'Fail reason')}
              </DataTable.HeadCell>
            )}
            <DataTable.HeadCell className='buttons'>
              <Button
                type='button'
                color='round-secondary'
                onClick={handleCustomizeClick}
                text={tr('FRONTEND.TRANSACTIONS.COLUMNS.CUSTOMIZE_BUTTON', 'Customize')}
                data-test='customize-columns'
                icon={<CustomizeIcon />}
              />
            </DataTable.HeadCell>
          </DataTable.Row>
        </DataTable.Head>

        <DataTable.Body>
          {transactions.map((data, index) => {
            const {
              id,
              amount,
              foreignTransactionId,
              status,
              reqTime,
              currency,
              direction,
              reference,
              accountName,
              paymentMethod,
              trnTypeLocalizationKey,
              checkoutDetails,
              paymentFlow,
              statusCodeLocalizationKey,
              statusCodeDefaultDescription
            } = data

            const { customerName, customerEmail } = checkoutDetails || {}

            const showDropdown =
              (enableRefunds || enablePayouts) &&
              [PaymentMethodEnum.BANKWIRE, PaymentMethodEnum.INSTANT_BANK_TRANSFER, PaymentMethodEnum.INTERAC].includes(
                paymentMethod
              ) &&
              direction === TransactionDirectionEnum.DEPOSIT &&
              [PaymentFlowEnum.CHECKOUT, PaymentFlowEnum.DEPOSIT].includes(paymentFlow) &&
              status === TransactionStatusEnum.PROCESSED

            return (
              <DataTable.Row key={index} data-test='table-row'>
                {columns.isVisible('id') && (
                  <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.ID', 'ID')}>{id}</DataTable.Cell>
                )}
                {columns.isVisible('foreignTransactionId') && (
                  <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.FOREIGN_ID', 'Provided ID')}>
                    {foreignTransactionId}
                  </DataTable.Cell>
                )}
                {columns.isVisible('account') && (
                  <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.ACCOUNT', 'Account')}>
                    {accountName}
                  </DataTable.Cell>
                )}
                <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.DATE', 'Time')} className='no-wrap'>
                  <div>
                    <span className='text-nowrap'>{formatDateLocal(reqTime)}</span>{' '}
                    <span className='text-nowrap'>{formatTime(reqTime)}</span>
                  </div>
                </DataTable.Cell>
                {columns.isVisible('type') && (
                  <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.TRANSACTION_TYPE', 'Type')}>
                    {tr(trnTypeLocalizationKey, fetchLastChunk(trnTypeLocalizationKey))}
                  </DataTable.Cell>
                )}
                {columns.isVisible('method') && (
                  <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.PAYMENT_METHOD', 'Payment Method')}>
                    {tr(`METADATA.PAYMENT_OPTIONS.${paymentMethod}`, paymentMethod)}
                  </DataTable.Cell>
                )}
                {columns.isVisible('description') && (
                  <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.DESCRIPTION', 'Description')}>
                    {formatTransactionDescription(data)}
                  </DataTable.Cell>
                )}
                {columns.isVisible('customer') && (
                  <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.CUSTOMER', 'Counterparty')}>
                    {extractCounterpartyName(data)}
                  </DataTable.Cell>
                )}
                {columns.isVisible('customerEmail') && (
                  <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.CUSTOMER_EMAIL', 'Customer email')}>
                    {customerEmail}
                  </DataTable.Cell>
                )}
                {columns.isVisible('customerName') && (
                  <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.CUSTOMER_NAME', 'Customer name')}>
                    {customerName}
                  </DataTable.Cell>
                )}
                {columns.isVisible('reference') && (
                  <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.REFERENCE', 'Reference')}>
                    {reference}
                  </DataTable.Cell>
                )}
                <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.AMOUNT', 'Amount')}>
                  {
                    <span className='amount'>
                      {formatMoney(
                        amount * (direction === 'WITHDRAW' ? -1 : 1),
                        currency,
                        getCurrencyPrecision(currency)
                      )}
                    </span>
                  }
                </DataTable.Cell>
                {columns.isVisible('status') && (
                  <DataTable.Cell
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS', 'Status')}
                    className='status-cell'>
                    <span className={cn('status', 'status-' + status.toLowerCase())}>
                      {getTransactionStatusName(status)}
                    </span>
                  </DataTable.Cell>
                )}
                {columns.isVisible('failReason') && (
                  <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS_DESCRIPTION', 'Fail reason')}>
                    {isValidString(statusCodeLocalizationKey)
                      ? tr(statusCodeLocalizationKey, statusCodeDefaultDescription)
                      : statusCodeDefaultDescription}
                  </DataTable.Cell>
                )}
                <DataTable.Cell className='preview-cell no-border'>
                  <div className='details-inner'>
                    {showDropdown ? (
                      <TransactionDropdown
                        transaction={data}
                        reload={reload}
                        enablePayouts={enablePayouts && hasAccessTo(PortalUserPermissionsEnum.PAYOUTS)}
                        enableRefunds={enableRefunds && hasAccessTo(PortalUserPermissionsEnum.REFUNDS)}
                        handleDetailsClick={handleDetailsClick}
                      />
                    ) : (
                      <DetailAlternativeIcon onClick={() => handleDetailsClick(id)} />
                    )}
                  </div>
                </DataTable.Cell>
              </DataTable.Row>
            )
          })}
        </DataTable.Body>
      </DataTable.Table>
    </TransactionsContainer>
  )
}
